<template>
  <a-row class="courses-list" :gutter="24">
    <a-col :xs="24" :md="24" :xl="16" :xxl="16" v-if="courses.length > 0">
      <a-row :gutter="24">
        <a-col
          class="gutter-row course-details"
          :xs="24"
          :md="12"
          :xl="8"
          :xxl="6"
          v-for="course in courses"
          :key="course._id"
        >
          <router-link
            :to="
              course.type == 'Group'
                ? '/candidate/course/' + course._id + '/subcourse' + loginAs
                : '/candidate/course/view/'+course._id + loginAs
            "
          >
            <div class="gutter-box">
              <div class="cImg">
                <img :src="course.image" />
              </div>
              <div class="chapters">
                <div class="title">
                  <div class="category">
                    <img
                      src="./../../../assets/images/archive.svg"
                      alt="category"
                      srcset=""
                    />
                    <span class="txt"> {{ course.category.title }}</span>
                    <!-- <img class="cIcon"
                    src="./../../../assets/images/users1.png"
                  /> -->
                  </div>
                  <div class="cours">
                    {{ course.title }}
                  </div>
                </div>
                <div class="counts">
                  <span>{{ course.total_chapter }}</span>
                  <p>Chapters</p>
                </div>
                <div class="progress">
                  <a-progress
                    :percent="
                      course.completed && course.completed > 0
                        ? course.completed
                        : 0
                    "
                  />
                  <div class="pText">
                    <span v-if="course.completed && course.completed > 0"
                      >Progress</span
                    >
                    <span v-else>Start Course</span>
                    <span
                      >{{
                        course.completed && course.completed > 0
                          ? course.completed.toFixed(2)
                          : 0
                      }}%</span
                    >
                  </div>
                </div>
                <a-button class="startbtn">
                  Start
                </a-button>
              </div>
            </div>
          </router-link>
        </a-col>
      </a-row>
    </a-col>
    <a-col :xs="24" :md="24" :xl="16" :xxl="16" v-else class="courses-list">
      <a-empty />
    </a-col>
    <a-col :xs="24" :md="24" :xl="8" :xxl="8">
      <div class="cCalendar">
        <a-calendar
          v-model:value="value"
          :fullscreen="false"
          @panelChange="onPanelChange"
        />
      </div>
      <div class="totalCours">
        <a-row :gutter="24">
          <a-col :xs="7">
            <div class="leftImg">
              <img
                src="./../../../assets/images/archive.svg"
                alt="category"
                srcset=""
              />
            </div>
          </a-col>
          <a-col :xs="17">
            <div class="rightCnt">
              <div class="counts">
                <p>Total Enrolled Courses <span style="text-align:center;">{{ courses.length }}</span></p>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { Moment } from 'moment'

import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import ICourse from '../../../interface/course'

export default defineComponent({
  props: ['courseId'],
  setup (props) {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const value = ref<Moment>()
    const onPanelChange = (value: Moment, mode: string) => {
      console.log(value, mode)
    }
    const courses = ref<Array<ICourse>>([])
    const getMyCourse = async profileId => {
      const responce = await courseService.getMyCourse(profileId)
      courses.value = responce.data
    }
    const getChildCourse = async courseId => {
      const profile = commonServices.getCurrentProfile()
      const responce = await courseService.getChildCourses(
        courseId,
        profile._id
      )
      courses.value = responce.data
    }
    onMounted(() => {
      const profile = commonServices.getCurrentProfile()
      if (props.courseId) {
        getChildCourse(props.courseId)
      } else {
        getMyCourse(profile._id)
      }
    })
    return {
      courses,
      value,
      onPanelChange,
      loginAs
    }
  }
})
</script>

<style lang="scss">
.courses-list {
  .course-details {
    margin: 0 0 30px 0;
    .gutter-box {
      background: #ffffff;
      min-height: 200px;
      height: 100%;
      box-shadow: 0px 16px 40px #7090b029;
      border-radius: 10px;
      cursor: pointer;
      .cImg {
        min-height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 120px;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        img {
          // max-width: 150px;
          width: 100%;
        }
      }
      .chapters {
        padding: 15px;
        .title {
          font-family: "TT Norms Pro Medium";
          text-align: left;
          .category {
            color: #b5b5b5;
            font-size: 12px;
            display: flex;
            margin: 0 0 3px 0;
            img {
              width: 15px;
              margin: 0 5px 0 0;
            }
            .txt {
              width: 100%;
            }
            .cIcon {
              width: 15px;
            }
          }
          .cours {
            color: #505050;
          }
        }
        .counts {
          text-align: left;
          display: flex;
          flex-direction: row;
          align-items: baseline;
          padding: 10px 0 0 0;
          span {
            font-family: "TT Norms Pro Medium";
            font-size: 27px;
            line-height: normal;
            color: #305299;
            margin: 0 5px 0 0;
          }
          p {
            margin: 0;
            color: #305299;
            font-family: "TT Norms Pro Regular";
          }
        }
      }
      .progress {
        .ant-progress-success-bg,
        .ant-progress-bg {
          background-color: #305299;
        }
        .ant-progress-show-info .ant-progress-outer {
          padding-right: 0;
        }
        .ant-progress-text {
          opacity: 0;
        }
        .pText {
          display: flex;
          justify-content: space-between;
          color: #707070;
          font-family: "TT Norms Pro Regular";
        }
      }
      .startbtn {
        background-color: #305299;
        border-radius: 16px;
        color: #ffffff;
        text-align: center;
        border: none;
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }
  .cCalendar {
    box-shadow: 0px 16px 40px #7090b014;
    border-radius: 25px;
    background-color: #fff;
    .ant-fullcalendar {
      font-size: 12px;
      border-top: none;
    }
    .ant-fullcalendar-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
      .ant-radio-group {
        display: none;
      }

      .ant-select-single .ant-select-selector {
        border: none;
        color: #1e2022;
        font-weight: bold;
        outline: none;
        border-color: transparent !important;
        box-shadow: none;
      }
    }
    .ant-fullcalendar-today .ant-fullcalendar-value,
    .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
      box-shadow: none;
      background: #dfe4fd;
      border: none;
      color: #455cc7;
      border-radius: 50%;
    }
    .ant-fullcalendar-selected-day .ant-fullcalendar-value,
    .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
      color: #fff;
      background: #455cc7 !important;
      border-radius: 50%;
    }
    .ant-fullcalendar-value:hover {
      background: #f5f5f5;
      cursor: pointer;
      border-radius: 50%;
    }
  }
  .totalCours {
    margin: 20px 0 0;
    .leftImg {
      box-shadow: 0px 16px 40px #7090b014;
      border-radius: 25px;
      background: #e6eeff;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 50%;
      }
    }
    .rightCnt {
      box-shadow: 0px 16px 40px #7090b014;
      border-radius: 25px;
      background-color: #fff;
      padding: 10px;
      height: 80px;
      display: flex;
      align-items: center;
      .counts {
        text-align: left;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        span {
          font-family: "TT Norms Pro Medium";
          font-size: 32px;
          line-height: normal;
          color: #305299;
          margin: 0 5px 0 0;
        }
        p {
          margin: 0;
          font-size: 16px;
          color: #707070;
          font-family: "TT Norms Pro Regular";
        }
      }
    }
  }
}
</style>
