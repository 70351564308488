
import { defineComponent, onMounted, ref } from 'vue'
import { Moment } from 'moment'

import courseService from '../../../services/course'
import commonServices from '../../../services/common'

import ICourse from '../../../interface/course'

export default defineComponent({
  props: ['courseId'],
  setup (props) {
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const value = ref<Moment>()
    const onPanelChange = (value: Moment, mode: string) => {
      console.log(value, mode)
    }
    const courses = ref<Array<ICourse>>([])
    const getMyCourse = async profileId => {
      const responce = await courseService.getMyCourse(profileId)
      courses.value = responce.data
    }
    const getChildCourse = async courseId => {
      const profile = commonServices.getCurrentProfile()
      const responce = await courseService.getChildCourses(
        courseId,
        profile._id
      )
      courses.value = responce.data
    }
    onMounted(() => {
      const profile = commonServices.getCurrentProfile()
      if (props.courseId) {
        getChildCourse(props.courseId)
      } else {
        getMyCourse(profile._id)
      }
    })
    return {
      courses,
      value,
      onPanelChange,
      loginAs
    }
  }
})
