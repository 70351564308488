
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'

import ICourse from '../../../interface/course'
import courseService from '../../../services/course'

import ListCourse from '@/components/candidate/course/ListCourse.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListCourse,
    ArrowLeftOutlined
  },
  setup () {
    const route = useRoute()
    const course = ref<ICourse>()
    const courseId = ref<string>(route.params.id as string)
    const getParentCourseDetails = async () => {
      const responce = await courseService.getCourseDetails(courseId.value)
      course.value = responce.data
    }
    onMounted(() => {
      getParentCourseDetails()
    })
    return {
      courseId,
      course
    }
  }
})
